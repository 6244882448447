@import "src/assets/styles/variables.scss";

.P-home-register {
  width: 100%;
  height: 100vh;
  overflow: hidden;
  position: relative;
  padding: 0 $padding;
  .P-register-content {
    position: relative;

    .P-updated {
      width: 160px;
      border: 1px solid black;
      border-radius: 20px;
      height: 30px;
      font-size: 14px;
      font-weight: 700;
      box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px,
        rgba(60, 64, 67, 0.15) 0px 1px 3px 1px;
    }

    h2,
    h3 {
      color: $blue-dark;
      position: relative;
      font-family: Regular;
    }

    a {
      width: 280px;
      background: $light-blue;
      box-shadow: 20px 8px black, inset 10px 10px $light-blue;
      font-size: 16px;
      position: relative;
      color: white;
      white-space: nowrap;
      transition: 0.5s;
    }

    h2 {
      text-transform: uppercase;
      font-size: 60px;
    }

    h3 {
      font-size: 30px;
      line-height: 40px;
      font-weight: 100;
    }

    .P-cover {
      width: 580px;
      height: 480px;
    }

    .P-new_logo {
      width: 120px;
      animation: rotate 8s linear infinite;
      position: absolute;
      left: 60%;
      bottom: 10%;
    }

    @keyframes rotate {
      to {
        transform: rotate(360deg);
      }
    }
  }

  .P-register {
    display: none;
  }
}

@media (max-width: 1400px) {
  .P-home-register {
    .P-register-content {
      h2 {
        font-size: 35px;
      }

      h3 {
        font-size: 25px;
        line-height: 30px;
      }
    }
  }
}

@media (max-width: 768px) {
  .P-home-register {
    width: 100%;
    height: 320px;
    background-size: cover;
    padding: 0;
    .P-register-content {
      margin-top: 50px;

      h1 {
        display: none;
      }

      h2 {
        font-size: 16px;
        margin-bottom: 15px;
        white-space: unset !important;
        text-align: center;
      }

      h3 {
        font-size: 13px;
        line-height: 25px;
        margin-bottom: 15px;
        text-align: center;
      }

      a,
      .P-updated,
      .P-cover,
      .P-new_logo {
        display: none;
      }

      .P-register {
        width: 150px;
        margin: 0 auto;
        display: block;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
  }
}
