@import "src/assets/styles/variables.scss";

.P-about-us {
  min-height: 100vh;

  .P-about-tag {
    width: 100%;
    height: 100vh;
    background-color: #f5f5f5;
    position: relative;
    * {
      text-align: center;
    }

    h1 {
      font-size: 60px;
      font-family: Regular;
      font-weight: 100;
    }

    h2 {
      margin-top: 40px;
      font-family: Regular;
    }

    span {
      font-weight: bold;
      font-family: "Bold";
    }

    .P-about-circle_1 {
      width: 120px;
      height: 120px;
      border-radius: 50%;
      border: 6px solid black;
      position: absolute;
      left: 50%;
      bottom: -5%;
      transform: translate(-50%, 0%);
      z-index: 10;
    }
  }

  .P-about-goal {
    width: 100%;
    height: 100vh;
    background-color: white;
    position: relative;
    padding: 0 $padding;

    .P-about-circle_2 {
      width: 120px;
      height: 120px;
      border-radius: 50%;
      border: 6px solid black;
      position: absolute;
      left: 50%;
      top: -5%;
      transform: translate(-50%, 0%);
    }

    .P-goal {
      margin-top: 100px;

      > div {
        margin-right: 100px;

        * {
          font-family: Regular;
        }
        h2 {
          color: #59c7f6;
          font-weight: 100;
          font-size: 30px;
        }
        h3 {
          font-size: 35px;
          margin-top: 20px;
          margin-bottom: 20px;
          font-weight: bold;
        }

        p {
          font-size: 20px;
          text-align: justify;
        }
      }

      img {
        width: 400px;
      }
    }
  }

  .P-about-team {
    width: 100%;
    height: auto;
    padding: 80px $padding;

    > h2 {
      color: #59c7f6;
      font-size: 30px;
      font-family: Regular;
      font-weight: 100;
    }

    > div {
      width: 100%;
    }
  }
}

@media screen and (max-width: 768px) {
  .P-about-us {
    min-height: 100vh;

    .P-about-tag {
      height: 50vh;
      padding: 0 20px;
      h1 {
        font-size: 18px;
      }

      h2 {
        font-size: 16px;
      }

      .P-about-circle_1 {
        width: 70px;
        height: 70px;
        border: 4px solid black;
      }
    }

    .P-about-goal {
      width: 100%;
      height: auto;
      padding: 0 25px;

      .P-about-circle_2 {
        width: 70px;
        height: 70px;
        top: -1%;
        border: 4px solid black;
      }

      .P-goal {
        margin-top: 100px;
        flex-direction: column;
        > div {
          text-align: center;
          margin-right: 0;

          h2 {
            font-size: 22px;
          }
          h3 {
            font-size: 20px;
          }

          p {
            font-size: 16px;
          }
        }

        img {
          display: none;
        }
      }
    }

    .P-about-team {
      width: 100%;
      height: auto;
      padding: 80px 20px;

      > h2 {
        font-size: 22px;
        text-align: center;
      }

      > div {
        overflow-x: auto;
        flex-wrap: unset;
      }
    }
  }
}
