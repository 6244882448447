@import "src/assets/styles/variables";

footer {
    width: 100%;
    height: 250px;
    background-color: $light;
    padding: 0 80px;
    position: relative;

    .logo {
        width: 140px;
    }

    a,
    p {
        font-family: Regular;
        letter-spacing: 1.56px;
        font-size: 18px;
        margin-right: 25px;
        cursor: pointer;
    }

    span {
        position: absolute;
        bottom: 1%;
        left: 50%;
        transform: translate(-50%, 0);
        font-family: Regular;
        letter-spacing: 1.56px;
        font-size: 16px;
    }

    .nkarner {
        .P-footer-address {
            * {
                font-size: 16px;
                font-family: Regular;
                font-weight: 100;
            }
        }
    }
}

@media (max-width: 1400px) {
    footer {
        a,
        p {
            font-size: 17px;
            margin-right: 10px;
            letter-spacing: 1px;
        }

        span {
            font-size: 15px;
        }
    }
}

@media (max-width: 768px) {
    footer {
        width: 100%;
        height: 500px;
        padding: 30px 0;
        margin-top: 50px;
        flex-wrap: unset;
        flex-direction: column;
        justify-content: unset;
        border-top: 0.5px solid $gray;

        > div {
            width: 100%;
            font-size: 12px;
            flex-direction: column;
            align-items: center;
            justify-content: center;
        }
        .logo {
            width: 100px;
        }

        .gracner {
            margin-left: 0;

            p, a {
                margin-bottom: 0;
                margin-top: 20px;
            }
        }

        .nkarner {
            margin-top: 40px;
            a {
                margin-right: 10px;
            }
        }
        a,
        p {
            font-size: 12px;
            margin-right: 0;
            // margin-top: 20px;
            margin-bottom: 0;
            text-align: center;
            white-space: nowrap;
        }

        .nkarner {
            .P-footer-address {
                * {
                    font-size: 12px;
                }
            }
        }

        span {
            width: 90%;
            letter-spacing: 1px;
            font-size: 9px;
            margin: 0 auto;
            bottom: 1%;
        }
    }
}
