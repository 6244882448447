@import "src/assets/styles/variables.scss";

.P-home-courses {
  width: 100%;
  position: relative;
  overflow: hidden;
  padding: 80px;
  background: $light;
  > a {
    font-size: 65px;
  }

  .S-modal-categories-right-btn {
    width: 35px;
    position: absolute;
    right: 5%;
    bottom: 15%;
    cursor: pointer;
    z-index: 555;
    transform: rotate(180deg);
  }

  .S-modal-categories-left-btn {
    width: 35px;
    position: absolute;
    left: 1%;
    bottom: 15%;
    cursor: pointer;
    z-index: 555;
  }

  .P-courses {
    padding-top: 60px;
    transition: all 1s;
    position: relative;
  }
}

@media (max-width: 1400px) {
  .P-home-courses {
    .P-courses {
      padding-top: 120px;
    }
    > a {
      font-size: 45px;
    }

    .P-2 {
      width: 150px;
    }
  }
}

@media (max-width: 768px) {
  .P-home-courses {
    padding: 20px;
    margin-top: 25px;

    > a {
      font-size: 25px;
      text-align: center;
    }

    .P-2 {
      display: none;
    }

    .S-modal-categories-right-btn,
    .S-modal-categories-left-btn {
      display: none;
    }

    .P-courses {
      padding-top: 50px;
      overflow-x: scroll;
    }
  }
}
