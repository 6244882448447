@import "src/assets/styles/variables";

.P-FAQ {
    min-height: 100vh;
    padding: 180px 80px;

    h1 {
        font-size: 65px;
        margin-bottom: 60px;
    }

    .Collapsible {
        width: 100%;

        >.Collapsible__trigger {
            width: 100%;
            height: 65px !important;
            border-radius: 10px;
            background: rgba(224, 224, 224, 0.907);
            padding: 20px;
            display: flex;
            align-items: center;
            justify-content: left;
            cursor: pointer;
            font-size: 18px;
            font-weight: 100;
            font-family: Regular;
        }

        >.Collapsible__contentOuter {
            width: 100%;
            border-radius: 10px;
            margin-top: 15px;
            margin-bottom: 15px;

            .Collapsible__contentInner {
                display: flex;
                align-items: center;
                justify-content: left;
                height: auto !important;
                padding: 20px;
                background: -webkit-linear-gradient(45deg, $light-blue, $blue);
                color: white !important;
                font-weight: 100 !important;
                font-size: 16px;
                font-family: Regular;
            }
        }
    }
}


@media (max-width: 768px) {

    .P-FAQ {
        padding: 150px 20px;

        h1 {
            font-size: 25px;
        }

        .Collapsible {

            >.Collapsible__trigger {
                height: auto !important;
                font-size: 15px;

            }

            >.Collapsible__contentOuter {

                .Collapsible__contentInner {
                    font-size: 14px;
                }
            }
        }
    }
}