@import "src/assets/styles/variables.scss";

.P-tutor-item {
  width: 27%;
  background-color: white;
  margin-bottom: 60px;
  border-radius: 40px;
  height: 400px;
  img {
    width: 200px;
    border-radius: 10px;
  }

  * {
    color: $blue-dark;
    text-align: center;
    margin-bottom: 15px;
    font-family: Regular;
  }

  h2 {
    font-size: 22px;
    font-weight: bold;
  }

  h3 {
    font-size: 20px;
  }

  h4 {
    font-size: 18px;
    font-weight: 100;
  }
}

@media (max-width: 768px) {
  .P-tutor-item {
    margin-bottom: 0;
    margin-right: 25px;
    min-width: 80%;

    * {
      margin-bottom: 10px;
    }

    h2 {
      font-size: 18px;
    }

    h3 {
      font-size: 16px;
    }

    h4 {
      font-size: 14px;
    }
  }
}
