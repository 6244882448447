@import "src/assets/styles/variables";

header {
  width: 100%;
  height: $header-height;
  z-index: 10;
  background-color: transparent;
  padding: 0 $padding;
  position: absolute;
  overflow: hidden;
  top: 40px;
  left: 0;
  .P-logo-block {
    img {
      width: 140px;
      margin-top: 10px;
    }
  }

  .P-header-links {
    a,
    p {
      margin-left: 30px;
      color: white;
      font-size: 18px;
      font-weight: 700;
      font-family: Regular;
      cursor: pointer;
      padding: 10px 0;
    }

    .active {
      border-bottom: 2px solid $light-blue;
    }
  }

  button {
    width: 250px;
    background: transparent !important;
    color: black;
    border : 2px solid black;
    font-weight: bold;
    font-size: 18px !important;
  }
}

@media (max-width: 1400px) {
  header {
    padding: 0 60px;
    .P-logo-block {
      .P-header-links a,
      p {
        font-size: 20px;
      }
    }

    button {
      width: 200px;
    }
  }
}

@media (max-width: 768px) {
  header {
    padding: 0;

    .P-logo-block {
      position: absolute;
      left: 50%;
      transform: translate(-50%, 0);
      img {
        width: 110px;
      }
    }

    button {
      display: none !important;
    }

    .P-header-links {
      display: none;
    }
  }
}
