@import "src/assets/styles/variables";

.P-resume-modal {
  width: 385px;
  background-color: $light-blue;
  position: fixed;
  right: 80px;
  bottom: 0;
  z-index: 1000;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  text-align: center;
  border-radius: 20px 20px 0 0;
  transition:all 0.3s ease;


  h2 {
    font-size: 16px;
    cursor: pointer;
  }

  h3 {
    margin-top: 20px;
    font-size: 14px;
    font-weight: 100;

  }
  * {
    color: white;
    font-family: Regular;
  }

  a {
    width: 50%;
    height: 35px;
    margin-top: 20px;
    background: white;
    color: $light-blue;
    font-weight: bold;
    font-size: 16px;
  }

  .arrow {
    position: absolute;
    right: 10px;
    top: 10px;
    font-size: 25px;
    cursor: pointer;
  }
}

@media screen and (max-width:768px) {
  .P-resume-modal {
    width: 70%;
    right: 0;
    bottom: 0;
  
    h2 {
      font-size: 14px;
    }
  
    h3 {
      font-size: 13px;
    }

    a {
      width: 70%;
      height: 30px;
      font-size: 14px;
    }
    .arrow {
      right: 20px;
      top: 20px;
    }
  }
  
}