@import "src/assets/styles/variables.scss";

.P-home-blog {
  width: 100%;
  padding: 80px;
  position: relative;
  background: $light;

  > a {
    font-size: 65px;
    text-align: center;
    text-transform: uppercase;
  }

  .P-blogs {
    padding-top: 60px;

    .P-blog-item {
      margin-bottom: 0;
    }
  }
}

@media (max-width: 1400px) {
  .P-home-blog {
    > a {
      font-size: 45px;
    }
  }
}

@media (max-width: 768px) {
  .P-home-blog {
    padding: 20px;
    > a {
      font-size: 25px;
    }

    .P-blogs {
      padding-top: 50px;
      flex-direction: column;

      > a {
        margin-bottom: 25px !important;
        &:last-child {
          margin-bottom: 0;
        }
      }
    }
  }
}
