@import "src/assets/styles/variables";

.P-promotion {
  width: 100%;
  height: 40px;
  position: relative;
  z-index: 10000000000;
  background-color: black;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  font-family: Regular;
  line-height: 17px;
  text-align: center;

  a {
    margin: 0;
    padding: 0;
    color: white;
    font-family: Regular;
    line-height: 17px;
    text-align: center;
    text-decoration: underline;
    margin-left: 5px;
  }
}

@media (max-width: 768px) {
  .P-promotion {
    font-size: 13px;
    padding: 0 10px;
  }
}
