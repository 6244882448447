@import "src/assets/styles/variables.scss";

.P-home-tutors {
  padding: 80px;
  background-color: $light;

  > a {
    font-size: 65px;
    text-align: right;
    white-space: nowrap;
    text-transform: uppercase;
  }

  .P-tutors-slider {
    padding-top: 60px;

    .P-tutor-item {
      width: 95%;
    }
  }
}

@media (max-width: 1400px) {
  .P-home-tutors {
    > a {
      font-size: 45px;
    }

    .P-tutors-slider {
      padding-top: 120px;
    }
  }
}

@media (max-width: 768px) {
  .P-home-tutors {
    padding: 20px;

    > a {
      font-size: 25px;
      text-align: center;
    }

    .P-tutors-slider {
      width: 100%;
      padding-top: 50px;

      .P-tutor-item {
        width: 95%;
      }
    }
  }
}
