@import "./variables";

@font-face {
  font-family: "Regular";
  src: url("../fonts/Arial-AMU-Regular-8891.woff2") format("woff2");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Bold";
  src: url("../fonts/Arial-AMU-Bold-8892.woff2") format("woff2");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

* {
  margin: 0;
  padding: 0;
  color: $black;
  box-sizing: border-box;
  outline: 0;
  font-family: "Bold", "Regular";
}

#root {
  background-color: $light;
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

a {
  text-decoration: none;
}

::-webkit-scrollbar {
  width: 3px;
}

::-webkit-scrollbar-track {
  background: #f1f1f1;
}

::-webkit-scrollbar-thumb {
  background: linear-gradient(45deg, $light-blue, $app-color);
}

.G-relative {
  position: relative;
}

.G-capitalize {
  text-transform: capitalize;
}

.G-main-color {
  color: $main-color;
}

.G-red-color {
  color: $red;
}

.G-green-color {
  color: $green;
}

.G-gray-color {
  color: $gray;
}

.G-bold {
  font-weight: bold;
}

.G-gray-dark-color {
  color: $gray-dark;
}

.G-blue-dark-color {
  color: $blue-dark;
}

.G-text-center {
  text-align: center;
}

.G-cursor {
  cursor: pointer;
}

.G-red {
  background-color: #af001e;
}

.G-green {
  color: #0bbf6b;
}

// #region Flex

.G-flex {
  display: flex;
}

.G-center {
  justify-content: center;
  align-items: center;
}

.G-justify-center {
  justify-content: center;
}

.G-justify-between {
  justify-content: space-between;
}

.G-justify-around {
  justify-content: space-around;
}

.G-justify-end {
  justify-content: flex-end;
}

.G-align-center {
  align-items: center;
}

.G-align-start {
  align-items: flex-start;
}

.G-align-end {
  align-items: flex-end;
}

.G-flex-column {
  flex-direction: column;
}

.G-flex-wrap {
  flex-wrap: wrap;
}

.G-page {
  width: 100%;
  min-height: 100vh;
  padding: 0 80px;
  position: relative;
  background-color: $light;
}

.G-mb-1 {
  margin-bottom: 10px;
}

.G-mb-2 {
  margin-bottom: 20px;
}

.G-mb-3 {
  margin-bottom: 30px;
}

.G-mb-4 {
  margin-bottom: 40px;
}

.G-mb-5 {
  margin-bottom: 50px;
}

.G-mb-6 {
  margin-bottom: 60px;
}

.G-mt-1 {
  margin-top: 10px;
}

.G-mt-2 {
  margin-top: 20px;
}

.G-mt-3 {
  margin-top: 30px;
}

.G-mt-4 {
  margin-top: 40px;
}

.G-mt-5 {
  margin-top: 50px;
}

.G-mt-6 {
  margin-top: 60px;
}

.G-mr-1 {
  margin-right: 10px;
}

.G-mr-2 {
  margin-right: 20px;
}

.G-mr-3 {
  margin-right: 30px;
}

.G-mr-4 {
  margin-right: 40px;
}

.G-mr-5 {
  margin-right: 50px;
}

.G-mr-6 {
  margin-right: 60px;
}

.G-ml-1 {
  margin-left: 10px;
}

.G-ml-2 {
  margin-left: 20px;
}

.G-ml-3 {
  margin-left: 30px;
}

.G-ml-4 {
  margin-left: 40px;
}

.G-ml-5 {
  margin-left: 50px;
}

.G-ml-6 {
  margin-left: 60px;
}

.G-btn {
  padding: 0 50px;
  height: $input-height;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  color: $white;
  background: $light-blue;
  // background: linear-gradient(45deg, $light-blue, $blue);
  border: 0;
  border-radius: 25px;
  letter-spacing: 1.5px;
  font-size: 20px;
  font-family: Regular;
  cursor: pointer;
  text-transform: uppercase;
  position: relative;
  overflow: hidden;
  white-space: nowrap;

  * {
    color: $white;
  }

  &.G-black-btn {
    background: $black;
  }

  &.G-red-btn {
    background: $red;
  }

  &.G-gray-btn {
    background: $light-gray;
  }

  &.G-green-btn {
    background: $green;
  }

  &.G-white-btn {
    background: $white;

    * {
      color: $black;
    }
  }
}

@media (max-width: 768px) {
  .G-page {
    padding: 0 20px;
  }

  .G-btn {
    height: $input-height - 10px;
    font-size: 15px;
  }
}

.G-gradient-text {
  // background: -webkit-linear-gradient(45deg, $light-blue, $blue);
  background: #0071bc;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
