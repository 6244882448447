@import "src/assets/styles/variables.scss";

.P-blog-item {
    width              : 32%;
    height             : 450px;
    position           : relative;
    background-size    : cover;
    background-position: center;
    background-repeat  : no-repeat;
    display            : flex;
    flex-direction     : column;
    justify-content    : flex-end;
    padding-bottom     : 25px;
    cursor             : pointer;

    .P-background-layer {
        width           : 100%;
        height          : 100%;
        position        : absolute;
        left            : 0;
        top             : 0;
        background-color: rgba(0, 0, 0, 0.8);
        transition      : 0.5s;
    }

    .P-blog-info {
        width  : 100%;
        height : 200px;
        padding: 20px;
    }

    h2,
    h3,
    h4 {
        position          : relative;
        color             : white;
        font-family       : Regular;
        display           : -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        overflow          : hidden;
        text-overflow     : ellipsis;
    }

    h2 {
        font-size     : 25px;
        letter-spacing: 1px;
    }

    h3 {
        color     : white;
        font-size : 20px;
        margin-top: 20px;
    }

    h4 {
        font-size   : 14px;
        text-align  : right;
        margin-top  : 20px;
        margin-right: 20px;
    }

    &:hover {
        .P-background-layer {
            background-color: rgba(95, 95, 95, 0.4);
        }
        .P-blog-info {
            background-color: $light-blue;
        }
    }
}

@media (max-width: 1400px) {
    .P-blog-item {

        .P-blog-info {
            width  : 100%;
            height : 200px;
        }

        h2 {
            font-size: 18px;
        }

        h3 {
            font-size : 15px;
        }

        h4 {
            font-size: 15px;
        }
    }
}


@media (max-width: 768px) {
    .P-blog-item {
        width : 100%;
        height: 400px;

        .P-blog-info {
            width  : 100%;
            height : 200px;
            padding: 20px;
        }

        h2 {
            font-size: 20px;
        }

        h3 {
            font-size : 15px;
            margin-top: 30px;
        }

        h4 {
            font-size: 10px;
        }
    }
}
