.P-success-message {
  width           : 650px;
  height          : 630px;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border-radius: 20px;
  background: #ffffff;
  padding: 0 40px;

  h2,
  h3 {
    font-family: Regular;
    font-weight: 100;
    letter-spacing: 1.15px;
    text-align: center;
    margin-top: 40px;
  }

  img {
    width: 170px;
  }
}


@media (max-width: 768px) {
  .P-success-message {
    width: 350px;
    height: 590px;
    padding: 0 10px;

    h2 {
      font-size: 15px;
    }

    h3 {
      font-size: 13px;
    }

    h2,
    h3 {
      margin-bottom: 20px;
    }

    img {
      width: 70px;
    }
  }
}