@import "src/assets/styles/variables";

.lds-default {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);

  div {
    position: absolute;
    width: 6px;
    height: 6px;
    background: $light-blue;
    border-radius: 50%;
    animation: lds-default 1.2s linear infinite;
  }

  .lds1 {
    animation-delay: 0s;
    top: 37px;
    left: 66px;
  }

  .lds2 {
    animation-delay: -0.1s;
    top: 22px;
    left: 62px;
  }

  .lds3 {
    animation-delay: -0.2s;
    top: 11px;
    left: 52px;
  }

  .lds4 {
    animation-delay: -0.3s;
    top: 7px;
    left: 37px;
  }

  .lds5 {
    animation-delay: -0.4s;
    top: 11px;
    left: 22px;
  }

  .lds6 {
    animation-delay: -0.5s;
    top: 22px;
    left: 11px;
  }

  .lds7 {
    animation-delay: -0.6s;
    top: 37px;
    left: 7px;
  }

  .lds8 {
    animation-delay: -0.7s;
    top: 52px;
    left: 11px;
  }

  .lds9 {
    animation-delay: -0.8s;
    top: 62px;
    left: 22px;
  }

  .lds10 {
    animation-delay: -0.9s;
    top: 66px;
    left: 37px;
  }

  .lds11 {
    animation-delay: -1s;
    top: 62px;
    left: 52px;
  }

  .lds12 {
    animation-delay: -1.1s;
    top: 52px;
    left: 62px;
  }

  @keyframes lds-default {
    0%,
    20%,
    80%,
    100% {
      transform: scale(1);
    }

    50% {
      transform: scale(1.5);
    }
  }
}
