@import "src/assets/styles/variables.scss";

.P-home-reviews {
    width: 100%;
    padding: 80px;
    background: $light;
    
    >h2 {
        font-size: 65px;
        text-align: center;
        text-transform: uppercase;
    }

    .P-reviews-list {
        padding-top: 60px;
        width: 100%;

        .P-review-item {
            width: 100%;
            margin: 0 auto;
            display: flex !important;
            align-items: center;
            justify-content: center;
            flex-direction: column;

            img {
                width: 170px;
                height: 170px;
                border-radius: 50%;
                margin-bottom: 20px;
            }

            * {
                color: $blue-dark;
            }

            .P-review-name {
                font-size: 28px;
                font-weight: 600;
                margin-bottom: 20px;
            }

            .P-review-studied {
                font-size: 20px;
                font-family: Regular;
                margin-bottom: 20px;
            }

            .P-review-comment {
                font-size: 20px;
                font-family: Regular;
                font-weight: 100;
                text-align: center;
                width: 50%;
                line-height: 24px;
            }
        }

        .slick-dots {
            display: none !important;
        }

        .slick-arrow {
            display: none !important;
        }
    }
}

@media (max-width: 1400px) {
    .P-home-reviews {
  
        >h2 {
            font-size: 45px;
        }
    }
}

@media (max-width: 768px) {
    .P-home-reviews {
        padding: 20px;
        margin-bottom: 0px;

        > h2 {
             font-size: 25px;
         }
    
        .P-reviews-list {
            padding-top: 50px;
    
            .P-review-item {

                * {
                    text-align: center;
                }
    
                img {
                    width: 80px;
                    height: 80px;
                }
    
                .P-review-name {
                    font-size: 18px;
                }
    
                .P-review-studied {
                    font-size: 16px;
                }
    
                .P-review-comment {
                    font-size: 14px;
                    width: 90%;
                }
            }
        }
    }
}

