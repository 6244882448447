@import "src/assets/styles/variables";

.P-apply-modal {
  width: 650px;
  height: 630px;
  background-color: white;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  padding: 40px;
  border-radius: 30px;

  h2,
  h3 {
    font-family: Regular;
    font-weight: 100;
    letter-spacing: 1.15px;
  }

  h2 {
    font-weight: bold;
  }

  .P-apply-form {
    width: 100%;
    margin: 0 auto;

    div {
      width: 100%;
      margin-top: 25px;
    }

    button {
      margin-top: 30px;
    }

    span {
      font-family: Regular;
      font-size: 16px;
    }

    input {
      width: 100%;
      height: 40px;
      font-size: 16px;
      border: 1px solid #adadad;
      font-family: Regular;
      font-size: 18px;
      margin-top: 15px;
      border-radius: 10px;
      padding: 0 10px;

      &::placeholder {
        font-size: 15px;
        font-family: Regular;
      }
    }

    button {
      width: 50%;
      background: $light-blue;
    }
  }

  .P-icon-close {
    display: none;
  }
}

@media (max-width: 768px) {
  .P-apply-modal {
    width: 350px;
    height: 520px;
    padding: 20px 10px;

    h2 {
      font-size: 12px;
    }

    h3 {
      font-size: 10px;
    }

    .P-apply-form {
      width: 100%;
      margin: 0 auto;

      div {
        span {
          font-size: 12px;
          margin-bottom: 10px;
        }
      }

      input {
        width: 100%;
        height: 30px;
        font-size: 14px;
        margin-top: 5px;

        &::placeholder {
          font-size: 12px;
        }
      }

      button {
        height: 30px;
        padding: 15px 0;
        margin-top: 30px;
      }
    }

    .P-icon-close {
      display: block;
      position: absolute;
      top: 3%;
      right: 3%;
      font-size: 18px;
    }
  }
}
