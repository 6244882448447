@import "src/assets/styles/variables.scss";

.P-about-values {
  width: 100%;
  padding: 80px $padding;

  * {
    font-family: Regular;
    text-align: center;
  }
  > h2 {
    color: #0071bc;
    font-weight: 100;
    font-size: 30px;
  }
  > h3 {
    font-size: 35px;
    margin-top: 20px;
    margin-bottom: 20px;
    font-weight: bold;
  }

  .P-values {
    width: 100%;

    > div {
      width: 25%;
      height: 300px;
      background-color: #f5f5f5;
      border-radius: 25px;
      padding: 40px;
      position: relative;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      margin-top: 100px;
      img {
        width: 200px;
        position: absolute;
        left: 50%;
        top: -35%;
        transform: translate(-50%, 0);
      }

      > h2 {
        font-size: 22px;
        margin-top: 20px;
      }
      > h3 {
        font-size: 18px;
        margin-top: 20px;
        font-weight: 100;
        line-height: 25px;
      }
    }
  }
}

@media screen and (max-width: 768px) {
  .P-about-values {
    padding: 80px 20px;

    > h2 {
      font-size: 22px;
    }
    > h3 {
      font-size: 20px;
    }

    .P-values {
      flex-direction: column;
      > div {
        width: 100%;
        margin-top: 100px;
        img {
          width: 200px;
          top: -30%;
        }
        > h2 {
          font-size: 20px;
        }
        > h3 {
          font-size: 15px;
        }
      }
    }
  }
}
