@import "src/assets/styles/variables.scss";

.P-home {
    background-color: $light;
}

@media screen and (max-width:768px) {
    
    .P-blog-video {
        margin-top: 70px;
    }
}