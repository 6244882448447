@import "src/assets/styles/variables.scss";

.P-blog-page {
  padding: 180px 80px;

  > h1 {
    font-size: 65px;
    text-transform: uppercase;
    text-align: center;
  }

  > div {
    padding-top: 60px;
  }
}

@media (max-width: 768px) {
  .P-blog-page {
    padding: 150px 20px;

    > h1 {
      font-size: 25px;
    }

    > div {
      padding-top: 50px;
    }
  }
}
