@import "src/assets/styles/variables.scss";

.P-blog-details {
  min-height: 140vh;
  padding-top: 180px;
  background-color: $light;

  .P-details-info {
    padding: 0 80px;

    h1 {
      font-size: 45px;
    }

    h3 {
      font-family: Regular;
    }
  }
  .P-details-cover {
    width: 100%;
    height: 80vh;
    background-size: cover;
    background-position: center;
    background-attachment: fixed;
  }

  .P-details-description {
    padding: 0 80px;
    line-height: 30px;
    margin: 30px auto;

    h2 {
      font-family: Regular;
      font-weight: 600;
      font-size: 26px;
    }

    h3 {
      font-family: Regular;
      font-weight: 100;

      a {
        font-family: Regular;
        text-decoration: underline;
        font-weight: 100;
      }
    }
  }

  .P-latest {
    padding: 80px;
    padding-bottom: 50px;

    h2 {
      font-family: Regular;
    }
  }
}

@media (max-width: 768px) {
  .P-blog-details {
    padding-top: 150px;

    .P-details-info {
      padding: 0 15px;

      h1 {
        font-size: 22px;
      }

      h3 {
        font-family: Regular;
        font-size: 16px;
      }
    }
    .P-details-cover {
      height: 50vh;
      background-size: cover;
      background-position: center;
      background-repeat: no-repeat;
      background-attachment: unset;
    }

    .P-details-description {
      padding: 0 15px;

      h2 {
        font-size: 19px;
      }

      h3 {
        font-family: Regular;
        font-weight: 100;
        font-size: 17px;
      }
    }

    .P-blog-video {
        padding: 0 15px;
    }

    .P-latest {
      padding: 0 15px;
      padding-bottom: 0;
      margin-top: 50px;
      > div {
        flex-direction: column;
      }

      > h2 {
        font-size: 18px;
        text-align: center;
        font-family: Regular;
      }
    }
  }
}
