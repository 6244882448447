@import "src/assets/styles/variables.scss";

.P-resume-page {
  background-color: $light;
  min-height: 100vh;
  position: relative;

  .P-resume-mobile {
    display: none;
  }

  .P-video {
    margin-top: 60px;
  }

  .P-resume-tag {
    width: 100%;
    height: 100vh;
    background-color: #f5f5f5;
    position: relative;
    * {
      text-align: center;
    }

    h1 {
      font-size: 60px;
      font-family: Regular;
      font-weight: 100;
    }

    h2 {
      margin-top: 40px;
      font-family: Regular;
    }

    span {
      font-weight: bold;
      font-family: "Bold";
    }

    button {
      margin-top: 40px;
      background: $light-blue;
    }
  }

  .P-resume-content {
    padding: 0 80px;
    * {
      font-family: Regular;
    }

    h2 {
      font-weight: bold;
      margin-bottom: 20px;
    }

    h3 {
      font-weight: 100;
      margin-bottom: 20px;
    }
  }

  .P-details-courses {
    padding: 0 80px;
    margin-bottom: 60px;

    h2 {
      font-family: Regular;
    }
  }
}

.P-resume-subscribe {
  width: 550px;
  height: 350px;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border-radius: 20px;
  background: white;
  padding: 0 40px;

  * {
    font-family: Regular;
    text-align: center;
  }

  h3 {
    font-weight: 100;
  }

  span {
    color: #ff3333;
    font-weight: bold;
  }

  input {
    width: 100%;
    height: 40px;
    font-size: 16px;
    border: 1px solid #adadad;
    font-family: Regular;
    font-size: 18px;
    border-radius: 10px;
    padding: 0 10px;

    &::placeholder {
      font-size: 15px;
      font-family: Regular;
    }
  }
}

@media screen and (max-width: 768px) {
  .P-resume-page {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    .P-resume-mobile {
      display: block;
      padding: 0 20px;
      margin-top: 150px;

      * {
        font-family: Regular;
      }

      h2 {
        text-align: center;
        margin-bottom: 20px;
        font-size: 18px;
        span {
          font-weight: bold;
        }
      }
    }
    .P-resume-tag {
      display: none;
      .P-resume-animation {
        display: none;
      }
    }

    .P-video {
      display: none;
    }

    .P-resume-content {
      display: none;
    }
    .P-details-courses {
      padding: 0 20px;
      width: 100%;

      h2 {
        font-size: 18px;
      }

      > div {
        overflow-x: scroll;
      }
    }
  }
  .P-resume-subscribe {
    display: none;
  }
}
