@import "src/assets/styles/variables.scss";

.P-course-item {
  min-width: 25%;
  max-width: 25%;
  min-height: 650px;
  position: relative;
  background-size: cover;
  background-position: center;
  margin-right: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  padding: 25px 0;

  &:hover {
    .P-background-layer {
      background-color: rgba(0, 0, 0, 0.1);
    }

    .P-course-title {
      background-color: $light-blue;
    }
  }
  .P-background-layer {
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
    background-color: rgba(0, 0, 0, 0.7);
    transition: 0.5s;
  }

  .P-course-title {
    width: 100%;
    height: 150px;
    font-size: 40px;
    position: relative;
    color: white;
    transition: 0.5s;
    padding: 0 10px;
  }
}

@media (max-width: 1400px) {
  .P-course-item {
    .P-course-title {
      font-size: 30px;
    }
  }
}

@media (max-width: 768px) {
  .P-course-item {
    min-width: 80%;
    min-height: 450px;

    .P-course-title {
      font-size: 25px;
    }
  }
}
