.P-promotion-modal {
  width: 550px;
  height: 500px;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border-radius: 20px;
  background: #ffffff;
  padding: 0 40px;

  h2,
  h3 {
    font-family: Regular;
    font-weight: 100;
    letter-spacing: 1.15px;
    text-align: center;
    margin-top: 40px;
  }

  img {
    width: 170px;
  }

  .P-icon-close {
    position: absolute;
    top: 3%;
    right: 3%;
    font-size: 25px;
    cursor: pointer;
  }
}


@media (max-width: 768px) {
  .P-promotion-modal {
    width: 300px;
    height: 300px;
    padding: 0 10px;

    h2,
    h3 {
      margin-top: 0;
      margin-bottom: 0;
    }

    h2 {
      font-size: 15px;
    }

    h3 {
      font-size: 13px;
      margin-top: 20px;
    }

    img {
      width: 100px;
      margin-bottom: 20px;
    }

    .P-icon-close {
      font-size: 18px;
    }
  }
}