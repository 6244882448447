@import "src/assets/styles/variables.scss";

.P-courses-page {
  min-height: 100vh;
  padding: 180px 80px;
  background-color: $light;

  h1 {
    font-size: 65px;
    text-align: center;
    text-transform: uppercase;
  }

  .P-course-list {
    width: 100%;
    padding-top: 60px;

    .P-course-item {
      min-width: 30%;
      max-width: 30%;
      margin-bottom: 40px;
    }
  }
}

@media (max-width: 768px) {
  .P-courses-page {
    padding: 150px 20px;

    h1 {
      font-size: 25px;
    }

    .P-course-list {
      padding-top: 50px;
      flex-direction: column;

      .P-course-item {
        min-width: 100%;
        max-width: 100%;
        margin-bottom: 40px;

        &:last-child {
          margin-bottom: 0;
        }
      }
    }
  }
}
