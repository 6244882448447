@import "src/assets/styles/variables.scss";

.P-course-details {
  min-height: 180vh;
  background-color: $light;

  .P-course-top {
    width: 100%;
    height: 85vh;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    position: relative;

    .P-course-title {
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      display: flex;

      h1,
      h3 {
        font-family: Regular;
        position: relative;
        color: white;
        text-align: center;
      }

      h2 {
        font-size: 35px;
        font-weight: bold;
      }

      h3 {
        font-size: 25px;
        font-weight: 100;
      }

      button {
        margin: 60px auto;
      }
    }

    .P-top-footer {
      width: 100%;
      height: 100px;
      position: relative;
      margin-bottom: 50px;

      > div {
        width: 100%;
        height: 80px;
        background-color: white;
        padding: 0 120px;

        div {
          span {
            font-family: Regular;
            letter-spacing: 1.56px;
            font-size: 20px;

            &:first-child {
              font-weight: 100;
            }

            &:last-child {
              font-weight: bold;
              margin-left: 20px;
            }

            del {
              color: gray;
              font-family: Regular;
              font-weight: 100;
              font-size: 18px;
            }
          }
        }
      }
    }
  }

  .P-background-layer {
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
    background-color: rgba(0, 0, 0, 0.8);
    transition: 0.5s;
  }

  .P-details-courses {
    padding: 120px;

    h2 {
      font-family: Regular;
    }
  }
}

.P-course-details-content {
  width: 100%;
  padding-right: 0;

  .P-course-collapse {
    position: relative;
    width: 60%;
    padding-top: 120px;
    padding-left: 120px;

    .P-arrow {
      width: 20px;
      transition: 0.5s;
      left: 90%;
      position: absolute;
      cursor: pointer;
    }

    p {
      &:nth-child(1) {
        font-size: 28px;
        font-family: Regular;
        font-weight: bold;
      }
    }

    .Collapsible__trigger {
      h3 {
        font-size: 25px;
        font-family: Regular;
        font-weight: 100;
      }
    }

    .P-title {
      font-size: 28px;
      margin-top: 10px;
      font-family: Regular;
      font-weight: bold;
      cursor: pointer;
    }

    .P-description {
      margin-left: 20px;
      margin-bottom: 20px;
      font-family: Regular;
      font-size: 22px;
      font-weight: 100;
    }
  }

  .P-details-tutor {
    width: 30%;
    background-color: $light;
    padding: 40px 0;

    .P-tutor-img {
      width: 250px;
    }

    .P-tutor-description {
      margin-top: 20px;

      h2,
      h3,
      h4 {
        margin-top: 20px;
        font-family: Regular;
        text-align: center;
      }

      h2 {
        font-size: 26px;
      }

      h3 {
        font-size: 21px;
      }

      h4 {
        font-size: 16px;
      }
    }
  }
}

@media (max-width: 1400px) {
  .P-course-details {
    .P-course-top {
      .P-course-title {
        h3 {
          font-size: 22px;
        }
      }

      .P-top-footer {
        > div {
          div {
            span {
              font-size: 17px;
            }
          }
        }
      }
    }
  }

  .P-course-details-content {
    width: 100%;

    .P-course-collapse {
      width: 100%;

      .P-arrow {
        width: 15px;
        left: 90%;
      }

      .Collapsible__trigger {
        h3 {
          font-size: 18px;
          font-family: Regular;
          font-weight: 100;
        }
      }

      .P-description {
        font-size: 16px;
      }
    }

    .P-details-tutor {
      margin-bottom: 40px;

      .P-tutor-img {
        width: 200px;
      }
    }
  }
}

@media (max-width: 768px) {
  .P-course-details {
    min-height: 100vh;

    .P-course-top {
      width: 100%;
      height: 85vh;

      .P-course-title {
        width: 100%;
        padding: 0 20px;

        h1 {
          font-size: 22px;
        }

        h3 {
          font-size: 16px;
        }

        button {
          margin: 30px auto;
        }
      }

      .P-top-footer {
        height: 100px;
        align-items: center;

        > div {
          padding: 15px;
          flex-direction: column;

          div {
            width: 100%;
            display: flex;
            align-items: center;
            margin-bottom: 5px;

            span {
              letter-spacing: 1px;
              font-size: 10px;
              width: 250px;

              &:last-child {
                font-weight: bold;
                // margin-top : 10px;
                margin-left: 0;
              }

              del {
                font-size: 9px;
              }
            }
          }
        }
      }
    }

    .P-details-courses {
      width: 100%;
      padding: 0 20px;

      h2 {
        font-size: 18px;
      }

      > div {
        overflow-x: scroll;
      }
    }
  }

  .P-course-details-content {
    width: 100%;
    flex-direction: column;

    .P-course-collapse {
      width: 100%;
      padding-top: 50px;
      padding-left: 20px;

      .P-arrow {
        width: 10px;
        left: 92%;
      }

      p {
        &:nth-child(1) {
          font-size: 18px;
        }
      }

      .Collapsible__trigger {
        h3 {
          font-size: 15px;
          font-family: Regular;
          font-weight: 100;
        }
      }

      .P-description {
        font-size: 14px;
        margin-left: 0;
        padding: 0 10px;
      }
    }

    .P-details-tutor {
      width: 100%;
      padding: 20px 0;
      overflow-x: scroll;
      flex-direction: unset;

      .P-tutor {
        width: 100%;
        margin-right: 20px;
        margin-bottom: 0;
        padding: 20px;
        display: flex;
        align-items: center;
        flex-direction: column;

        .P-tutor-img {
          width: 80%;
          min-width: 250px;
        }

        .P-tutor-description {
          width: 100%;
          margin-top: 10px;

          h2,
          h3,
          h4 {
            margin-top: 20px;
            font-family: Regular;
          }

          h2 {
            font-size: 20px;
          }

          h3 {
            font-size: 16px;
          }

          h4 {
            font-size: 12px;
          }
        }
      }
    }
  }
}
